import { Text } from '@latitude/text';
import { animated, useTransition } from '@react-spring/web';
import Image from 'next/image';
import { PortoLogoIcon } from '@anchorage/common/dist/components/Icons';
import { getFormPageIllustration, getLoginLink } from 'components/porto/Signup/helpers';
import type { PortoSignupFormPage } from '../../types';
import { PORTO_SIGNUP_FORM_PAGES, PORTO_SIGNUP_FORM_TITLES_BY_PAGE } from '../../constants';
type Props = {
  page: PortoSignupFormPage;
};
const ILLUSTRATION_ALT_TEXT_BY_PAGE = {
  [PORTO_SIGNUP_FORM_PAGES.COMPLETE_YOUR_PROFILE]: 'Complete your profile illustration',
  [PORTO_SIGNUP_FORM_PAGES.ORGANIZATION_DETAILS]: 'Organization details illustration'
};
function SideBannerContent({
  page
}: Props) {
  const transitionFade = useTransition([page], {
    from: {
      opacity: 0
    },
    enter: {
      opacity: 1,
      delay: 200
    },
    leave: {
      opacity: 0
    },
    config: {
      duration: 200
    }
  });
  return <div className="size-full flex flex-col items-start justify-between relative p-10 bg-color-ref-primary-primary-50" data-sentry-component="SideBannerContent" data-sentry-source-file="SideBannerContent.tsx">
      <PortoLogoIcon className="h-10 absolute left-10 top-9 fill-black" data-sentry-element="PortoLogoIcon" data-sentry-source-file="SideBannerContent.tsx" />
      <div className="grid h-full">
        {transitionFade((style, page) => <animated.div className="h-full flex flex-col row-span-full col-span-full items-center justify-center gap-10 text-center" style={style}>
            <Image loading="eager" width={395} src={getFormPageIllustration(page)} alt={ILLUSTRATION_ALT_TEXT_BY_PAGE[page]} />
            <Text variant="headingLarge" className="text-black">
              {PORTO_SIGNUP_FORM_TITLES_BY_PAGE[page]}
            </Text>
          </animated.div>)}
      </div>
      <div className="w-full flex justify-center text-black ">
        <Text data-sentry-element="Text" data-sentry-source-file="SideBannerContent.tsx">
          {`${'Already have an account? '}`}
          <a className="font-bold" href={getLoginLink()}>
            Log in
          </a>
        </Text>
      </div>
    </div>;
}
export default SideBannerContent;