import { Button } from '@latitude/button';
import cn from 'classnames';
import type { PortoSignupFormPage } from '../../../types';
import { PORTO_SIGNUP_FORM_PAGES } from '../../../constants';
type Props = {
  page: PortoSignupFormPage;
  setPage: (page: PortoSignupFormPage) => void;
  onSubmit: () => void;
  isDisabled: boolean;
  isLoading: boolean;
};
function FormButtons({
  page,
  setPage,
  onSubmit,
  isDisabled,
  isLoading
}: Props) {
  const isFirstPage = page === PORTO_SIGNUP_FORM_PAGES.COMPLETE_YOUR_PROFILE;
  const showBackButton = !isFirstPage;
  const handleBackButton = () => {
    if (page === PORTO_SIGNUP_FORM_PAGES.ORGANIZATION_DETAILS) {
      setPage(PORTO_SIGNUP_FORM_PAGES.COMPLETE_YOUR_PROFILE);
    }
  };
  const handleContinueButton = () => {
    if (page === PORTO_SIGNUP_FORM_PAGES.COMPLETE_YOUR_PROFILE) {
      setPage(PORTO_SIGNUP_FORM_PAGES.ORGANIZATION_DETAILS);
    } else if (page === PORTO_SIGNUP_FORM_PAGES.ORGANIZATION_DETAILS) {
      onSubmit();
    }
  };
  const isLastFormPage = page === PORTO_SIGNUP_FORM_PAGES.ORGANIZATION_DETAILS;
  return <div className={cn({
    ['flex w-full justify-center']: true,
    ['justify-end sm:justify-end']: !showBackButton,
    ['justify-between sm:justify-between']: showBackButton
  })} data-sentry-component="FormButtons" data-sentry-source-file="FormButtons.tsx">
      {showBackButton && <Button type="outline" onClick={handleBackButton} htmlType="button">
          Back
        </Button>}
      <Button htmlType="button" onClick={handleContinueButton} disabled={isDisabled} loading={isLastFormPage ? isLoading : false} data-sentry-element="Button" data-sentry-source-file="FormButtons.tsx">
        {isLastFormPage ? 'Submit' : 'Continue'}
      </Button>
    </div>;
}
export default FormButtons;