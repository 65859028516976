import { Card } from '@latitude/card';
import { useFormContext } from '@anchorage/common/dist/components/Form/_reactHookForm';
import { FormInput, FormSelect } from '@anchorage/common/dist/components/Form/components';
import type { SelectOption } from '@anchorage/common/dist/components/SelectV2/types';
type Props = {
  countryOptions: SelectOption<string, string>[];
  isCountryOptionsLoading: boolean;
};
function OrganizationDetailsContent({
  countryOptions,
  isCountryOptionsLoading
}: Props) {
  const {
    control
  } = useFormContext();
  return <Card className="w-full flex flex-col gap-6" data-sentry-element="Card" data-sentry-component="OrganizationDetailsContent" data-sentry-source-file="OrganizationDetailsContent.tsx">
      <FormInput control={control} id="organizationName" name="organizationName" label="Organization legal name" required data-sentry-element="FormInput" data-sentry-source-file="OrganizationDetailsContent.tsx" />
      <FormSelect control={control} id="organizationCountry" name="organizationCountry" label="Country of incorporation" options={countryOptions} required isLoading={isCountryOptionsLoading} data-sentry-element="FormSelect" data-sentry-source-file="OrganizationDetailsContent.tsx" />
    </Card>;
}
export { OrganizationDetailsContent };