import { Card } from '@latitude/card';
import { Text } from '@latitude/text';
import { useFormContext } from '@anchorage/common/dist/components/Form/_reactHookForm';
import { FormCheckbox, FormInput, FormSelect } from '@anchorage/common/dist/components/Form/components';
import type { SelectOption } from '@anchorage/common/dist/components/SelectV2/types';
import { PORTO_TERMS } from 'components/porto/Signup/constants';
type Props = {
  countryOptions: SelectOption<string, string>[];
  isCountryOptionsLoading: boolean;
};
function CompleteYourProfileContent({
  countryOptions,
  isCountryOptionsLoading
}: Props) {
  const {
    control
  } = useFormContext();
  return <div className="w-full flex flex-col gap-10" data-sentry-component="CompleteYourProfileContent" data-sentry-source-file="CompleteYourProfileContent.tsx">
      <Card className="grid grid-cols-1 sm:grid-cols-2 gap-6" data-sentry-element="Card" data-sentry-source-file="CompleteYourProfileContent.tsx">
        <FormInput containerClassName="col-span-full" control={control} id="mainUserEmail" name="mainUserEmail" label="Email" type="email" required data-sentry-element="FormInput" data-sentry-source-file="CompleteYourProfileContent.tsx" />
        <FormInput control={control} id="mainUserFirstName" name="mainUserFirstName" label="First name" required data-sentry-element="FormInput" data-sentry-source-file="CompleteYourProfileContent.tsx" />
        <FormInput control={control} id="mainUserLastName" name="mainUserLastName" label="Last name" required data-sentry-element="FormInput" data-sentry-source-file="CompleteYourProfileContent.tsx" />
        <FormSelect containerClassName="col-span-full" control={control} id="mainUserCountry" name="mainUserCountry" label="Country of residence" options={countryOptions} isLoading={isCountryOptionsLoading} required data-sentry-element="FormSelect" data-sentry-source-file="CompleteYourProfileContent.tsx" />
      </Card>
      <div>
        <FormCheckbox control={control} label={<Text asChild>
              <span>I have read and accept the following:</span>
            </Text>} name="mainUserCheckbox" data-sentry-element="FormCheckbox" data-sentry-source-file="CompleteYourProfileContent.tsx" />
        <ul className="flex flex-col gap-2 list-disc ml-12">
          {PORTO_TERMS.map(({
          title,
          url
        }) => <li key={title} className="text-color-ref-text-link-primary">
              <Text asChild className=" hover:text-color-ref-text-strong">
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {title}
                </a>
              </Text>
            </li>)}
        </ul>
      </div>
    </div>;
}
export { CompleteYourProfileContent };