import { Button } from '@latitude/button';
import { Card } from '@latitude/card';
import { Text } from '@latitude/text';
import Image from 'next/image';
import { PortoLogoIcon } from '@anchorage/common/dist/components/Icons';
import { PORTO_HELP_EMAIL, REGISTRATION_COMPLETE_CARDS } from 'components/porto/Signup/constants';
import RegistartionCompleteIllustration from '../../images/registration-complete.svg';
const RegistrationCompletePage = () => {
  return <div className="size-full" data-sentry-component="RegistrationCompletePage" data-sentry-source-file="RegistrationCompletePage.tsx">
      <PortoLogoIcon className="h-10 absolute left-10 top-9" data-sentry-element="PortoLogoIcon" data-sentry-source-file="RegistrationCompletePage.tsx" />
      <div className="w-fit h-full mx-auto">
        <div className="max-w-[1120px] h-full lg:h-auto m-0 lg:m-40 lg:mb-0 flex flex-col gap-20 justify-center lg:justify-normal">
          <div className="px-6 py-10 sm:p-20 lg:p-0 grid content-center [grid-template-areas:'image''title''description'] lg:[grid-template-areas:'title_image''description_image'] gap-10 lg:gap-x-20 lg:gap-y-10">
            <Text variant="metricHuge" className="[grid-area:title]" data-sentry-element="Text" data-sentry-source-file="RegistrationCompletePage.tsx">
              Registration complete
            </Text>
            <div className="[grid-area:description]">
              <Text variant="bodyRegular" data-sentry-element="Text" data-sentry-source-file="RegistrationCompletePage.tsx">
                Thank you for registering your organization with Porto.
                We&apos;ve received your information. Check your email to
                complete the enrollment process by following the provided steps.
              </Text>
              <br />
              <Text variant="bodyRegular" data-sentry-element="Text" data-sentry-source-file="RegistrationCompletePage.tsx">
                Need help? Feel free to reach out to us at{' '}
                <Text asChild variant="link" className="text-color-ref-text-link-primary" data-sentry-element="Text" data-sentry-source-file="RegistrationCompletePage.tsx">
                  <a href={`mailto:${PORTO_HELP_EMAIL}`} target="_blank" rel="noopener noreferrer">
                    {PORTO_HELP_EMAIL}
                  </a>
                </Text>
                .
              </Text>
            </div>
            <Image loading="eager" src={RegistartionCompleteIllustration} width={280} alt="illustration" className="[grid-area:image] justify-self-center" data-sentry-element="Image" data-sentry-source-file="RegistrationCompletePage.tsx" />
          </div>
          <div className="hidden lg:flex flex-col w-full gap-5">
            <Text variant="headingMedium" data-sentry-element="Text" data-sentry-source-file="RegistrationCompletePage.tsx">In the meantime...</Text>
            <div className="grid grid-cols-3 gap-5 w-full">
              {REGISTRATION_COMPLETE_CARDS.map(({
              title,
              content,
              url
            }, index) => <Card key={`registration-complete-card-${index}`} className="min-h-[281px] px-6 py-8 text-color-ref-text-strong">
                    <div className="h-full flex flex-col justify-between gap-6">
                      <div className="flex flex-col gap-2">
                        <Text variant="headingSmall">{title}</Text>
                        <Text variant="bodyRegular">{content}</Text>
                      </div>
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        <Button variant="neutral" type="outline" size="small">
                          Learn more
                        </Button>
                      </a>
                    </div>
                  </Card>)}
            </div>
          </div>
        </div>
      </div>
    </div>;
};
export { RegistrationCompletePage };