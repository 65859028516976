import { Text } from '@latitude/text';
import { Tooltip } from '@latitude/tooltip';
import { useMemo } from 'react';
import type { PortoSignupFormPage } from 'components/porto/Signup/types';
import { PORTO_SIGNUP_FORM_PAGES } from '../../../constants';
type Props = {
  page: PortoSignupFormPage;
};
function FormHeader({
  page
}: Props) {
  const {
    description,
    step,
    title
  } = useMemo(() => {
    switch (page) {
      case PORTO_SIGNUP_FORM_PAGES.COMPLETE_YOUR_PROFILE:
        return {
          description: 'Start by entering a few personal details. Failure to provide legitimate information may cause disruptions to the account.',
          step: 1,
          title: 'Complete your profile'
        };
      case PORTO_SIGNUP_FORM_PAGES.ORGANIZATION_DETAILS:
        return {
          description: "Now, fill in your organization's information.",
          step: 2,
          title: 'Organization details'
        };
    }
  }, [page]);
  return <div className="flex flex-col gap-0 sm:gap-2" data-sentry-component="FormHeader" data-sentry-source-file="FormHeader.tsx">
      <Text data-sentry-element="Text" data-sentry-source-file="FormHeader.tsx">Step {step} of 2</Text>
      <Text variant="metricHuge" className="hidden sm:block" data-sentry-element="Text" data-sentry-source-file="FormHeader.tsx">
        {title}
      </Text>
      <Text variant="headingMedium" className="block sm:hidden" data-sentry-element="Text" data-sentry-source-file="FormHeader.tsx">
        {title}
      </Text>
      <Text variant="labelMedium" data-sentry-element="Text" data-sentry-source-file="FormHeader.tsx">
        {description}
        <Tooltip className="text-clip" side="bottom" asChild text={<div className="flex flex-col items-start gap-3">
              <Text variant="labelMedium" className="text-start">
                We collect this information to comply with relevant laws and
                regulations. We only use it for business purposes and do not
                share it for any other reason. Failure to provide legitimate
                information may cause disruptions to the account.
              </Text>
            </div>} data-sentry-element="Tooltip" data-sentry-source-file="FormHeader.tsx">
          <Text asChild variant="labelMedium" className="text-color-ref-text-link-primary pl-1" data-testid="tooltip-text" data-sentry-element="Text" data-sentry-source-file="FormHeader.tsx">
            <span>Why is this important?</span>
          </Text>
        </Tooltip>
      </Text>
    </div>;
}
export default FormHeader;