import { Text } from '@latitude/text';
import { useToast } from '@latitude/toast';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormProvider, useForm } from '@anchorage/common/dist/components/Form/_reactHookForm';
import { ObjectSchema, bool, object, string } from '@anchorage/common/dist/components/Form/_yup';
import { yupResolver } from '@anchorage/common/dist/components/Form/_yupResolver';
import { PortoLogoIcon } from '@anchorage/common/dist/components/Icons';
import { UIDeveloperError, handleError } from '@anchorage/sentry';
import { isNotEmpty } from '@anchorage/utils';
import type { PortoSignupFormPage } from '../../types';
import { PORTO_SIGNUP_FORM_PAGES } from '../../constants';
import FormButtons from '../Form/FormButtons/FormButtons';
import FormContent from './FormContent/FormContent';
import FormHeader from './FormHeader/FormHeader';
const SUBMIT_ERROR_MESSAGE = `We couldn't submit your information due to a technical issue on our end. Please try refreshing the page. If the issue keeps happening, contact us at help@porto.com.`;
type LoginFormValues = {
  mainUserEmail: string;
  mainUserFirstName: string;
  mainUserLastName: string;
  mainUserCountry: string;
  mainUserCheckbox: boolean;
  organizationName: string;
  organizationCountry: string;
};
const COMPLETE_YOUR_PROFILE_FORM_VALIDATION: ObjectSchema<any> = object({
  mainUserEmail: string().email('Invalid email').required('Email is required'),
  mainUserFirstName: string().required('First name is required'),
  mainUserLastName: string().required('Last name is required'),
  mainUserCountry: string().required('Country is required'),
  mainUserCheckbox: bool().oneOf([true], 'Please check').required('Please check.')
});
const ORGANIZATION_DETAILS_FORM_VALIDATION: ObjectSchema<any> = object({
  organizationName: string().required('Name is required'),
  organizationCountry: string().required('Country is required')
});
type Props = {
  page: PortoSignupFormPage;
  setPage: (page: PortoSignupFormPage) => void;
  onSuccess: () => void;
  isDisabled?: boolean;
};
function Form({
  page,
  setPage,
  onSuccess,
  isDisabled = false
}: Props) {
  const {
    toast
  } = useToast();
  const {
    executeRecaptcha
  } = useGoogleReCaptcha();
  const getResolver = () => {
    if (page === PORTO_SIGNUP_FORM_PAGES.COMPLETE_YOUR_PROFILE) {
      return yupResolver(COMPLETE_YOUR_PROFILE_FORM_VALIDATION);
    }
    if (page === PORTO_SIGNUP_FORM_PAGES.ORGANIZATION_DETAILS) {
      return yupResolver(ORGANIZATION_DETAILS_FORM_VALIDATION);
    }
    return undefined;
  };
  const methods = useForm<LoginFormValues>({
    mode: 'onChange',
    resolver: getResolver(),
    disabled: isDisabled
  });
  useEffect(() => {
    // Reset form state when changing pages but keep inputted values.
    // This is needed because when going back to a previous page, the form state is not being reset to just the previous page's values
    methods.reset(undefined, {
      keepValues: true
    });
  }, [page, methods]);
  const {
    mutate: finalizePortoRegistration,
    isPending: isFinalizePortoRegistrationLoading
  } = useMutation({
    mutationFn: (params: Record<string, any>) => fetch('/v1/submit', {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      body: JSON.stringify(params)
    }),
    onSuccess: onSuccess,
    onError: error => {
      toast({
        variant: 'danger',
        title: 'Unable to submit',
        description: SUBMIT_ERROR_MESSAGE
      });
      handleError(new UIDeveloperError(`Error submitting data to /v1/submit - ${error}`, error));
    }
  });
  const onSubmit = useCallback(async (data: LoginFormValues) => {
    if (!executeRecaptcha) {
      handleError(new UIDeveloperError('Execute recaptcha not yet available'));
      toast({
        variant: 'danger',
        title: 'Unable to submit',
        description: SUBMIT_ERROR_MESSAGE
      });
      return;
    }
    executeRecaptcha('signup').then(token => {
      finalizePortoRegistration({
        org: {
          name: data.organizationName,
          country: data.organizationCountry
        },
        users: [{
          first_name: data.mainUserFirstName,
          last_name: data.mainUserLastName,
          email: data.mainUserEmail,
          country: data.mainUserCountry
        }].filter(isNotEmpty),
        token
      });
    });
  }, [finalizePortoRegistration, executeRecaptcha, toast]);
  return <form className="size-full px-4 py-10 sm:p-20 flex flex-col gap-10 items-start overflow-y-scroll" data-sentry-component="Form" data-sentry-source-file="index.tsx">
      <PortoLogoIcon className="h-10 fill-white overflow-visible block lg:hidden" data-sentry-element="PortoLogoIcon" data-sentry-source-file="index.tsx" />
      <FormHeader page={page} data-sentry-element="FormHeader" data-sentry-source-file="index.tsx" />
      <FormProvider {...methods} data-sentry-element="FormProvider" data-sentry-source-file="index.tsx">
        <FormContent page={page} data-sentry-element="FormContent" data-sentry-source-file="index.tsx" />
      </FormProvider>
      <div className="w-full flex flex-1 items-end">
        <FormButtons page={page} setPage={setPage} onSubmit={() => {
        methods.handleSubmit(onSubmit)();
      }} isDisabled={!methods?.formState?.isValid} isLoading={isFinalizePortoRegistrationLoading} data-sentry-element="FormButtons" data-sentry-source-file="index.tsx" />
      </div>
      <Text variant="captionMedium" className="w-full text-color-ref-text-muted text-left lg:text-center" data-sentry-element="Text" data-sentry-source-file="index.tsx">
        This site is protected by reCAPTCHA and the Google{' '}
        <a className="text-color-ref-ui-icon-link" href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>{' '}
        and{' '}
        <a className="text-color-ref-ui-icon-link" href="https://policies.google.com/terms?hl=en" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{' '}
        apply.
      </Text>
    </form>;
}
export default Form;