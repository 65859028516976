import { useQuery } from '@tanstack/react-query';
import { UIDeveloperError, handleError } from '@anchorage/sentry';
import type { PortoSignupFormPage } from '../../../types';
import { PORTO_SIGNUP_FORM_PAGES } from '../../../constants';
import { CompleteYourProfileContent } from './CompleteYourProfileContent/CompleteYourProfileContent';
import { OrganizationDetailsContent } from './OrganizationDetailsContent/OrganizationDetailsContent';
type Props = {
  page: PortoSignupFormPage;
};
type CountriesData = Array<{
  code: string;
  name: string;
}>;
function FormContent({
  page
}: Props) {
  const {
    isPending: isLoadingCountriesData,
    data: countriesData
  } = useQuery({
    queryKey: ['countries'],
    queryFn: () => fetch('/v1/countries').then(res => res.json()).catch(error => {
      handleError(new UIDeveloperError(`Error fetching countries from /v1/countries - ${error}`, error));
    })
  });
  const countries = (countriesData as CountriesData)?.map(({
    name
  }) => ({
    label: name,
    value: name
  }));
  switch (page) {
    case PORTO_SIGNUP_FORM_PAGES.COMPLETE_YOUR_PROFILE:
      return <CompleteYourProfileContent isCountryOptionsLoading={isLoadingCountriesData} countryOptions={countries} />;
    case PORTO_SIGNUP_FORM_PAGES.ORGANIZATION_DETAILS:
      return <OrganizationDetailsContent isCountryOptionsLoading={isLoadingCountriesData} countryOptions={countries} />;
  }
}
export default FormContent;