import { SentryEnvironments } from '@anchorage/sentry';
import { PortoSignupFormPage } from './types';
import { PORTO_LOGIN_URLS, PORTO_SIGNUP_FORM_PAGES } from './constants';
import CompleteYourProfileIllustration from './images/complete-your-profile.svg';
import OrganizationDetailsIllustration from './images/organization-details.svg';
export const getLoginLink = () => {
  switch (window?.env?.SENTRY_ENVIRONMENT) {
    case SentryEnvironments.DEVELOPMENT:
      return PORTO_LOGIN_URLS.DEVELOPMENT;
    case SentryEnvironments.STAGING:
      return PORTO_LOGIN_URLS.STAGING;
    case SentryEnvironments.PRODUCTION:
    default:
      return PORTO_LOGIN_URLS.PRODUCTION;
  }
};
export const getFormPageIllustration = (page: PortoSignupFormPage) => {
  switch (page) {
    case PORTO_SIGNUP_FORM_PAGES.COMPLETE_YOUR_PROFILE:
      return CompleteYourProfileIllustration;
    case PORTO_SIGNUP_FORM_PAGES.ORGANIZATION_DETAILS:
      return OrganizationDetailsIllustration;
  }
};