import { PortoSignupFormPage } from './types';

export const PORTO_HELP_EMAIL = 'portohelp@anchorage.com';

export const REGISTRATION_COMPLETE_CARDS = [
  {
    title: 'Hold stablecoins, earn rewards',
    content:
      "Collect rewards on cash or treasury reserves through Anchorage Digital's stablecoin rewards program. Your funds are always on-platform and accessible any time.",
    url: 'https://www.anchorage.com/platform/stablecoin-rewards',
  },
  {
    title: "Learn about Atlas, Anchorage Digital's Settlement network",
    content:
      'Benefit from the highest level of regulatory protections, proven security, and enhanced capital efficiency.',
    url: 'https://www.anchorage.com/platform/settlement',
  },
  {
    title: 'Discover A1 Trading',
    content:
      'Trade via API, a fully featured, self-service trading interface, or navigate large-size, illiquid asset, and complex trade requests with the help of our expert traders available 24/7/365.',
    url: 'https://www.anchorage.com/platform/trading',
  },
];

export const PORTO_SIGNUP_FORM_PAGES = {
  COMPLETE_YOUR_PROFILE: 'COMPLETE_YOUR_PROFILE',
  ORGANIZATION_DETAILS: 'ORGANIZATION_DETAILS',
} as const;

export const PORTO_SIGNUP_FORM_TITLES_BY_PAGE: Record<
  PortoSignupFormPage,
  string
> = {
  COMPLETE_YOUR_PROFILE: 'The self-custody wallet for institutions',
  ORGANIZATION_DETAILS: 'Transact ERC-20 tokens and connect to dApps',
};

export const PORTO_LOGIN_URLS = {
  DEVELOPMENT: 'https://dashboard.dev.porto.xyz/login/',
  STAGING: 'https://dashboard.staging.porto.xyz/login/',
  PRODUCTION: 'https://dashboard.porto.xyz/login/',
} as const;

export const PORTO_TERMS = [
  {
    title: 'Porto wallet terms and conditions',
    url: 'https://www.anchorage.com/platform/self-custody/porto-wallet-terms-and-conditions',
  },
  {
    title: 'Porto legal disclosures',
    url: 'https://www.anchorage.com/platform/self-custody/porto-legal-disclosures',
  },
  {
    title: 'Porto wallet privacy policy',
    url: 'https://www.anchorage.com/platform/self-custody/porto-privacy-notice',
  },
];
