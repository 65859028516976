import { animated, easings, useChain, useSpringRef, useTransition } from '@react-spring/web';
import Head from 'next/head';
import { useState } from 'react';
import Form from './components/Form';
import { RegistrationCompletePage } from './components/RegistrationCompletePage/RegistrationCompletePage';
import SideBannerContent from './components/SideBannerContent/SideBannerContent';
import useIsPortoApp from 'utils/useIsPortoApp';
import type { PortoSignupFormPage } from './types';
import { PORTO_SIGNUP_FORM_PAGES } from './constants';
const Signup = () => {
  const isPorto = useIsPortoApp();
  const [wasSubmittedSuccessfully, setWasSubmittedSuccessfully] = useState(false);
  const [currentPage, setCurrentPage] = useState<PortoSignupFormPage>(PORTO_SIGNUP_FORM_PAGES.COMPLETE_YOUR_PROFILE);
  const transitionSideBarRef = useSpringRef();
  const transitionSideBar = useTransition([!wasSubmittedSuccessfully], {
    ref: transitionSideBarRef,
    initial: {
      left: '0px'
    },
    leave: {
      left: '-509px'
    },
    config: {
      duration: 400,
      easing: easings.easeInCubic
    }
  });
  const transitionRegistartionCompletePageRef = useSpringRef();
  const transitionRegistartionCompletePage = useTransition([wasSubmittedSuccessfully], {
    ref: transitionRegistartionCompletePageRef,
    initial: {
      opacity: 0
    },
    enter: {
      opacity: 1
    },
    config: {
      duration: 400
    }
  });
  const transitionFormRef = useSpringRef();
  const transitionForm = useTransition([!wasSubmittedSuccessfully], {
    ref: transitionFormRef,
    initial: {
      opacity: 1
    },
    leave: {
      opacity: 0
    },
    config: {
      duration: 200
    }
  });
  useChain([transitionSideBarRef, transitionFormRef, transitionRegistartionCompletePageRef], [0, 0, 1.0], 200);
  if (!isPorto) {
    return <></>;
  }
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        <title>Porto - Registration</title>
      </Head>
      <div className="w-full h-screen bg-color-ref-ui-background-base">
        {transitionSideBar((style, isVisible) => isVisible ? <animated.div className="hidden lg:block w-[509px] h-full fixed z-[1]" style={style}>
              <SideBannerContent page={currentPage} />
            </animated.div> : null)}
        {transitionForm((style, isVisible) => isVisible ? <animated.div style={style} className="flex size-full fixed">
              <div className="hidden lg:block max-w-[509px] flex-1">
                {/* Invisible padding, for sidebar */}
              </div>
              <div className="flex-1">
                <Form page={currentPage} setPage={setCurrentPage} onSuccess={() => {
            setWasSubmittedSuccessfully(true);
          }} isDisabled={wasSubmittedSuccessfully} />
              </div>
            </animated.div> : null)}
        {transitionRegistartionCompletePage((style, isVisible) => isVisible ? <animated.div style={style} className="size-full">
              <RegistrationCompletePage />
            </animated.div> : null)}
      </div>
    </>;
};
export default Signup;